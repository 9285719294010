@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   html {
    @apply font-poppins;
  }
    :root {
      --color-primary:#2C2A29;
      --color-secondary:#F1F2F0 ;
      --color-dark_blue:#004887;
      /* --color-secondary: rgb(111 114 185); */
      /* ... */
    }
  }

  .btn-one {
    line-height: 40px;
    height: 40px;
    text-align: center;
    width: 120px;
    cursor: pointer;
    background-color: #3c3c3c;
    color: #fff;
    transition: all 0.3s;
    position: relative;
  }
  
  .btn-one span {
    transition: all 0.3s;
  }
  
  .btn-one::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: rgba(255, 255, 255, 0.5);
    border-bottom-color: rgba(255, 255, 255, 0.5);
    transform: scale(0.1, 1);
  }
  
  .btn-one:hover span {
    letter-spacing: 2px;
  }
  
  .btn-one:hover::before {
    opacity: 1;
    transform: scale(1, 1);
  }
  
  .btn-one::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .btn-one:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
  }
  